import { ClientPortalSettingBrandingTextSource } from '@bloom/codegen/models/ClientPortalSettingBrandingTextSource';
import { CustomFontResponse } from '@bloom/codegen/models/CustomFontResponse';
import { QuestionnaireTextSettingSource } from '@bloom/codegen/models/QuestionnaireTextSettingSource';

export async function addFontSource(props: {
  customFonts: Readonly<Array<CustomFontResponse>>;
  doc?: Document;
  fontName: string;
  source?: QuestionnaireTextSettingSource | ClientPortalSettingBrandingTextSource;
  weight?: string;
}) {
  const {
    customFonts,
    doc = document,
    fontName,
    source = QuestionnaireTextSettingSource.BLOOM,
    weight = '400',
  } = props;

  const styleElement = doc.createElement('style');

  if (source === QuestionnaireTextSettingSource.BLOOM) {
    switch (fontName) {
      case 'Sharp Grotesk Medium':
        styleElement.appendChild(
          doc.createTextNode(`
@font-face {
  font-family: "Sharp Grotesk Medium;
  src: url('https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/SharpGroteskMedium25.woff2');
  font-weight: normal;
  font-style: normal;
}
`)
        );
        break;
      case 'Work Sans':
        styleElement.appendChild(
          document.createTextNode(`
    @font-face {
      font-family: "Work Sans";
      src: url('https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/WorkSansRegular.woff2');
      font-weight: normal;
      font-style: normal;
    }
  `)
        );
        break;
      case 'Gotham Rounded Book':
        styleElement.appendChild(
          doc.createTextNode(`
@font-face {
  font-family: "Gotham Rounded Book";
  src: url('https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/GothamRounded-Book.woff2');
  font-weight: normal;
  font-style: normal;
}
`)
        );
        break;
      default:
        styleElement.appendChild(
          doc.createTextNode(
            `@import url('https://fonts.googleapis.com/css?family=${fontName
              .split(' ')
              .join('+')}:400');`
          )
        );
        break;
    }
  }

  if (source === QuestionnaireTextSettingSource.GOOGLE) {
    styleElement.appendChild(
      doc.createTextNode(
        `@import url('https://fonts.googleapis.com/css?family=${fontName
          .split(' ')
          .join('+')}:${weight}');`
      )
    );
  }

  if (source === QuestionnaireTextSettingSource.CUSTOM) {
    const font = customFonts.find((font) => font.displayName === fontName);

    if (font) {
      styleElement.appendChild(
        doc.createTextNode(`
          @font-face {
            font-family: "${fontName}";
            src: url('${font.url}');
            font-weight: normal;
            font-style: normal;
          }
          `)
      );
    }
  }

  styleElement.dataset.fontFamily = fontName;
  doc.head.appendChild(styleElement);
}
