import { useMemo } from 'react';

import { Moment } from 'moment';

import { useMoment } from '@bloom/library/components/hooks/useMoment';
import { convertDateToObject } from '@bloom/library/utils/date';

export function useCalendarGridParams(date: Moment | string) {
  const { moment } = useMoment();

  return useMemo(() => {
    const calendarDate = typeof date === 'string' ? moment().set(convertDateToObject(date)) : date;

    const firstDateOfMonth = calendarDate.clone().startOf('month');
    const lastDateOfMonth = calendarDate.clone().endOf('month');

    const firstDateOfTheGrid = firstDateOfMonth.subtract(firstDateOfMonth.day(), 'days');
    const lastDateOfTheGrid = lastDateOfMonth.add(7 - lastDateOfMonth.day(), 'days');

    return {
      endDate: lastDateOfTheGrid.format('YYYY-MM-DD'),
      startDate: firstDateOfTheGrid.format('YYYY-MM-DD'),
    };
  }, [date, moment]);
}
