import React from 'react';

import { IIconProps } from './types';

export const SortSwapIcon: React.FC<IIconProps> = ({
  width = 20,
  className = '',
  color = 'currentColor',
}) => (
  <svg className={className} fill="none" height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M5.244 2.744a.833.833 0 0 1 1.179 0l4.166 4.167a.833.833 0 0 1-1.178 1.178L6.667 5.345v11.322a.833.833 0 0 1-1.667 0V5.345L2.256 8.09a.833.833 0 1 1-1.179-1.178l4.167-4.167Zm8.09 11.91V3.335a.833.833 0 0 1 1.666 0v11.32l2.744-2.743a.833.833 0 0 1 1.179 1.178l-4.167 4.167a.833.833 0 0 1-1.178 0L9.41 13.089a.833.833 0 0 1 1.178-1.178l2.744 2.744Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
