import React from 'react';

import { twMerge } from 'tailwind-merge';

import style from './DynamicInput.module.scss';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  'data-testid': string;
  errorMessage?: string;
  ref?: React.RefObject<HTMLInputElement | null>;
  wrapperClassName?: string;
}

const DynamicInput: React.FC<IProps> = (props) => {
  const {
    className,
    errorMessage = '',
    placeholder,
    ref,
    value = '',
    wrapperClassName,
    ...restProps
  } = props;

  // leave the training space to prevent collapse
  const content = placeholder && !value ? placeholder : `${value} `;

  return (
    <label
      className={twMerge(style.wrapper, 'relative grid overflow-hidden', wrapperClassName)}
      data-content={content}
    >
      <input
        className={twMerge(
          'absolute top-0 left-0 z-10 h-full w-full cursor-text overflow-auto rounded-sm bg-transparent p-3 outline-hidden placeholder:italic',
          errorMessage ? 'placeholder:text-danger text-danger' : '',
          className
        )}
        placeholder={placeholder}
        ref={ref}
        type="text"
        value={value}
        {...restProps}
        style={{ fontFamily: 'inherit', fontSize: 'inherit', lineHeight: 'inherit' }}
      />
    </label>
  );
};

export { DynamicInput };
