import React from 'react';

import { QuestionnaireColorTheme } from '@bloom/codegen/models/QuestionnaireColorTheme';
import { QuestionType } from '@bloom/codegen/models/QuestionType';

import CompanyLogo from '@bloom/library/components/CompanyLogo';
import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';
import { usePublicAccount } from '@bloom/library/components/hooks/usePublicAccount';
import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';
import {
  useQuestions,
  useQuoteRequest,
  useSelectedPackage,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { H1 } from '@bloom/library/components/Typography/H1';
import { escapeHTML } from '@bloom/library/utils/string';

const QuoteRequestHeaderUi: React.FC<
  React.PropsWithChildren<{
    logoSrc: string;
    logoText: string;
    progress: number;
    progressBarColor: string;
  }>
> = (props) => {
  const { children, logoSrc, logoText, progress, progressBarColor } = props;

  return (
    <header className="relative flex items-center py-3 md:h-32">
      <div className="mx-auto flex w-full max-w-3xl flex-col items-center gap-2 px-6 sm:flex-row sm:gap-4 lg:gap-6">
        {logoSrc || (!logoSrc && !logoText) ? (
          <CompanyLogo removeBloomBranding={false} src={logoSrc} />
        ) : null}

        {logoText && !logoSrc ? (
          <H1
            as="div"
            className="truncate"
            dangerouslySetInnerHTML={{ __html: escapeHTML(logoText) }}
            data-testid="logo-fallback"
          />
        ) : null}

        {children}
      </div>

      <div className="bg-black-15 dark:bg-white-50 absolute top-full left-0 h-0.5 w-full">
        <div
          className="absolute top-0 left-0 h-full bg-black dark:bg-white"
          style={{
            backgroundColor: progressBarColor,
            width: `${progress}%`,
          }}
        />
      </div>
    </header>
  );
};

const QuoteRequestHeader: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [
    {
      instantBooking,
      modal: { currentView, questionIndex },
      questionnaire: { accountId, owner, settings },
    },
  ] = useQuoteRequest();

  const questions = useQuestions();

  const { account } = usePublicAccount(accountId);
  const customColor = useCustomColor(settings?.button?.color || account?.settings?.customColor);

  let stepCount = questions.length;
  const instantBookingIndex = questions.findIndex((q) => q.type === QuestionType.BOOKING_PACKAGE);

  const hasInstantBookingQuestion = instantBookingIndex > -1;
  const selectedPackage = useSelectedPackage();

  if (hasInstantBookingQuestion) {
    // Note, Instant Booking contains 3 required steps,
    // one of them is counted as the question itself.
    stepCount += 2;

    if (selectedPackage) {
      if (!selectedPackage.bookWithoutPayment) {
        stepCount += 1;
      }
      if (selectedPackage.package.addons.length > 0) {
        stepCount += 1;
      }
      if (selectedPackage.contracts?.length > 0) {
        stepCount += selectedPackage.contracts.length;
      }
    }
  }

  let step = questionIndex;
  if ([QuoteRequestScreenEnum.OUTRO, QuoteRequestScreenEnum.SUMMARY].includes(currentView)) {
    step = stepCount;
  } else if (hasInstantBookingQuestion && questionIndex >= instantBookingIndex) {
    step += instantBooking.stepHistory.length;
  }

  const logoSrc =
    settings?.colorTheme === QuestionnaireColorTheme.DARK ? account?.logoDark : account?.logo;

  return (
    <QuoteRequestHeaderUi
      logoSrc={logoSrc || ''}
      logoText={account?.homepageName || `${owner.firstName} ${owner.lastName}`}
      progress={(100 * step) / stepCount}
      progressBarColor={customColor}
    >
      {children}
    </QuoteRequestHeaderUi>
  );
};

export { QuoteRequestHeader, QuoteRequestHeaderUi };
