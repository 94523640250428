import { useMemo } from 'react';

import { useMutation } from '@tanstack/react-query';

import { SimpleUserRequest } from '@bloom/codegen/models/SimpleUserRequest';
import { TempCredentialRequest } from '@bloom/codegen/models/TempCredentialRequest';
import { TempCredentialResource } from '@bloom/codegen/models/TempCredentialResource';
import { TempCredentialResponse } from '@bloom/codegen/models/TempCredentialResponse';

import { AsyncStatusEnum, useFetch } from './useFetch';

export function useStorageToken() {
  const { post } = useFetch();

  async function handleGetStorageToken(userDetails: SimpleUserRequest) {
    const response = await post<
      { 'temp-credential': TempCredentialResponse },
      TempCredentialRequest
    >('/api/temp-credentials', {
      resource: TempCredentialResource.FILE_RESOLVER_TOKEN,
      ...(!!userDetails && { userDetails }),
    });

    if (response.status === AsyncStatusEnum.SUCCESS) {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem('storage_token', response.data['temp-credential'].sessionToken);
      }
    }
    return response;
  }

  const { mutateAsync: getStorageToken, isPending } = useMutation({
    mutationFn: handleGetStorageToken,
  });

  return useMemo(() => ({ getStorageToken, isPending }), [getStorageToken, isPending]);
}

export function useEntriToken() {
  const { post } = useFetch();

  async function handleGetEntriToken() {
    const response = await post<
      { 'temp-credential': TempCredentialResponse },
      TempCredentialRequest
    >('/api/temp-credentials', { resource: TempCredentialResource.ENTRI_TOKEN });

    return response;
  }

  const { mutateAsync: getEntriToken, isPending } = useMutation({
    mutationFn: handleGetEntriToken,
  });

  return useMemo(() => ({ getEntriToken, isPending }), [getEntriToken, isPending]);
}
