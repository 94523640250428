import React from 'react';

import { IIconProps } from './types';

const FileIcon: React.FC<IIconProps> = ({ width = 20, className = '', color = 'currentColor' }) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M2.399.732A2.5 2.5 0 014.167 0H12.5c.221 0 .433.088.59.244l5 5a.833.833 0 01.243.59V17.5a2.5 2.5 0 01-2.5 2.5H4.167a2.5 2.5 0 01-2.5-2.5v-15A2.5 2.5 0 012.399.732zm1.768.935a.833.833 0 00-.834.833v15a.833.833 0 00.834.833h11.666a.834.834 0 00.834-.833V6.667H12.5a.833.833 0 01-.833-.834V1.667h-7.5zm9.166 1.178L15.488 5h-2.155V2.845zM5.833 7.5c0-.46.373-.833.834-.833h1.666a.833.833 0 110 1.666H6.667a.833.833 0 01-.834-.833zm0 3.333c0-.46.373-.833.834-.833h6.666a.833.833 0 010 1.667H6.667a.833.833 0 01-.834-.834zm0 3.334c0-.46.373-.834.834-.834h6.666a.833.833 0 010 1.667H6.667a.833.833 0 01-.834-.833z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
export { FileIcon };
