import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const ButtonIcon: React.FC<IIconProps> = ({
  width = 16,
  className = '',
  color = 'currentColor',
}) => (
  <svg className={className} height={width} viewBox="0 0 16 16" width={width}>
    <path
      clipRule="evenodd"
      d="M2.815 4.667c-.518 0-.815.363-.815.667v5.333c0 .304.297.667.815.667h10.37c.518 0 .815-.363.815-.667V5.334c0-.304-.297-.667-.815-.667H2.815Zm-2.148.667c0-1.169 1.03-2 2.148-2h10.37c1.119 0 2.148.831 2.148 2v5.333c0 1.17-1.03 2-2.148 2H2.815c-1.119 0-2.148-.83-2.148-2V5.334Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export { ButtonIcon };
