import React, { useId } from 'react';

import { twMerge } from 'tailwind-merge';

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  ['data-testid']: string;
  errorMessage?: string;
  invalid?: boolean;
  label?: string;
  labelClassName?: string;
  textareaClassName?: string;
}

const Textarea: React.FC<IProps> = (props) => {
  const {
    children,
    className = '',
    disabled,
    errorMessage,
    id,
    invalid,
    label,
    labelClassName,
    placeholder,
    readOnly,
    textareaClassName,
    ...restTextareaProps
  } = props;

  const defaultId = useId();
  const htmlId = id || defaultId;

  return (
    <fieldset
      className={twMerge(
        'relative flex rounded-sm border',
        !!errorMessage || invalid ? 'border-danger text-danger' : '',
        readOnly || disabled ? 'border-dashed' : '',
        !errorMessage && !invalid ? 'border-black-15 hover:border-black-50' : '',
        !errorMessage && !invalid ? 'dark:border-white-15 dark:hover:border-white-50' : '',
        !errorMessage && !invalid ? 'has-[:focus]:border-black' : '',
        !errorMessage && !invalid ? 'has-[:focus]:dark:border-white' : '',

        className
      )}
      data-focused="false"
    >
      <textarea
        aria-invalid={`${!!errorMessage || !!invalid}`}
        className={twMerge(
          'h-32 resize-none',
          'border-none bg-transparent',
          'font-regular peer w-full flex-1 appearance-none rounded-sm px-4 py-3 text-sm outline-hidden transition-colors delay-300 placeholder:italic',
          'placeholder:text-black-50 dark:placeholder:text-white-50 text-black dark:text-white',
          label ? 'placeholder:focus:text-transparent dark:focus:placeholder:text-transparent' : '',
          textareaClassName
        )}
        disabled={disabled}
        placeholder={placeholder}
        readOnly={readOnly}
        {...restTextareaProps}
        id={htmlId}
      />

      <legend
        className={twMerge(
          'pointer-events-none ml-3 h-0 overflow-hidden text-xs text-transparent duration-200',
          'px-1 peer-placeholder-shown:px-0 peer-focus:px-1',
          'text-xs peer-placeholder-shown:text-[0] peer-focus:text-xs',
          'w-auto peer-placeholder-shown:w-0 peer-focus:w-auto',
          label ? '' : '!px-0'
        )}
      >
        {label}
      </legend>

      <label
        className={twMerge(
          labelClassName,
          'absolute top-0 left-4 z-10 max-w-[calc(100%-1.25rem)] -translate-y-1/2 cursor-text truncate text-xs not-italic duration-150',
          'peer-placeholder-shown:top-5 peer-placeholder-shown:left-4 peer-placeholder-shown:text-sm peer-placeholder-shown:italic',
          'peer-focus:top-0 peer-focus:left-4 peer-focus:text-xs peer-focus:not-italic',
          !!errorMessage || invalid ? 'border-danger text-danger' : '',
          label ? 'opacity-100 peer-placeholder-shown:opacity-0 peer-focus:opacity-100' : '',
          !errorMessage && !invalid && !readOnly
            ? 'text-black-50 dark:text-white-50 peer-focus:text-black dark:peer-focus:text-white'
            : ''
        )}
        htmlFor={htmlId}
        title={label}
      >
        {label}
      </label>

      {errorMessage ? (
        <p className="text-danger bg-red-light absolute top-full left-0 mt-1 max-w-full truncate rounded-sm px-1 py-0.5 text-xs">
          {errorMessage}
        </p>
      ) : null}

      {children}
    </fieldset>
  );
};

export { Textarea };
