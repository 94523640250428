import { useQuery } from '@tanstack/react-query';

import { BookingPackageResponse } from '@bloom/codegen/models/BookingPackageResponse';

import { emptyObject } from '@bloom/ui/utils/empty-value';

import { AsyncStatusEnum, useFetch } from '@bloom/library/components/hooks/useFetch';

import { SharedQueryKeyEnum } from './interface';

export function useBookingPackages(userId: string) {
  const { get } = useFetch();

  async function fetchAlbumsList() {
    const response = await get<{ 'booking-packages': Array<BookingPackageResponse> }>(
      `/api/public-booking-packages/${userId}`
    );

    if (response.status === AsyncStatusEnum.SUCCESS) {
      return response.data['booking-packages'].reduce(
        (acc: { [packageId: string]: BookingPackageResponse }, p) => ({ ...acc, [p.id]: p }),
        {}
      );
    }

    return emptyObject;
  }

  return useQuery<{ [packageId: string]: BookingPackageResponse }>({
    enabled: !!userId,
    placeholderData: emptyObject,
    queryFn: fetchAlbumsList,
    queryKey: [SharedQueryKeyEnum.BOOKING_PACKAGES, userId],
  });
}
