'use client';
import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import merge from 'deepmerge';

import { OrderItemResponse } from '@bloom/codegen/models/OrderItemResponse';
import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';
import { QuestionnaireColorTheme } from '@bloom/codegen/models/QuestionnaireColorTheme';
import { QuestionnaireEmbedType } from '@bloom/codegen/models/QuestionnaireEmbedType';
import { QuestionnaireTextSettingSource } from '@bloom/codegen/models/QuestionnaireTextSettingSource';
import { QuestionType } from '@bloom/codegen/models/QuestionType';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { doNothing, emptyArray, emptyObject } from '@bloom/ui/utils/empty-value';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { useBookingPackages } from '@bloom/library/components/hooks/useBookingPackages';
import { AsyncStatusEnum, useFetch } from '@bloom/library/components/hooks/useFetch';
import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';
import {
  IQuoteRequest,
  IQuoteRequestInstantBooking,
  QuoteRequestErrors,
} from '@bloom/library/store/types';
import { RecursivePartial, TResponse } from '@bloom/library/types/general';
import { IQuestion } from '@bloom/library/types/questionnaire';

const quoteRequestInitState: Readonly<IQuoteRequest> = {
  dateTimePicker: {
    selectedDate: '',
    selectedTime: '',
    selectedTimezoneName: '',
  },
  instantBooking: {
    addons: emptyArray,
    clientInfo: {},
    contracts: emptyArray,
    selectedPackageId: '',
    step: 'packages',
    stepHistory: emptyArray,
  },
  isPreview: false,
  modal: {
    answerGroupId: '',
    answers: emptyArray,
    currentView: QuoteRequestScreenEnum.QUESTION,
    errors: {},
    isShown: false,
    isTriggeredByWidget: false,
    isValidationOn: false,
    questionIndex: 0,
    sentAnswers: {},
    trigger: '',
  },
  questionnaire: {
    questions: [],
    settings: {
      alignment: QuestionnaireAlignment.CENTER,
      backgroundColor: '#FFFFFF',
      button: { borderRadius: 0, color: '#FF325A', text: '' },
      colorTheme: QuestionnaireColorTheme.LIGHT,
      embedType: QuestionnaireEmbedType.DIRECT,
      hasBorder: false,
      hasShadow: false,
      isMultiStep: true,
      text: {
        body: {
          color: '#000000',
          fontFamily: 'Work Sans',
          fontSize: '14',
          fontWeight: '400',
          source: QuestionnaireTextSettingSource.BLOOM,
        },
        header: {
          color: '#000000',
          fontFamily: 'Sharp Grotesk Medium',
          fontSize: '24',
          fontWeight: '400',
          source: QuestionnaireTextSettingSource.BLOOM,
        },
      },
      textColor: '#000000',
    },
  },
};

type TAction =
  | { type: 'hide-modal' }
  | { type: 'reset-state' }
  | {
      mergeOptions?: merge.Options;
      type: 'update-quote-request-state';
      value: RecursivePartial<IQuoteRequest>;
    }
  | {
      type: 'toggle-quote-request-addons';
      value: OrderItemResponse;
    }
  | {
      type: 'update-quote-request-addons';
      value: OrderItemResponse;
    };

const QuoteRequestContext = createContext<[IQuoteRequest, Dispatch<TAction>]>([
  quoteRequestInitState,
  doNothing,
]);

function quoteRequestReducer(state: Readonly<IQuoteRequest>, action: TAction) {
  switch (action.type) {
    case 'hide-modal': {
      return merge<Readonly<IQuoteRequest>, RecursivePartial<IQuoteRequest>>(state, {
        modal: { isShown: false, sentAnswers: {} },
      });
    }
    case 'reset-state': {
      return {
        ...quoteRequestInitState,
        isPreview: state.isPreview,
        modal: {
          ...quoteRequestInitState.modal,
          currentView:
            state.questionnaire.introText || state.questionnaire.introTitle
              ? QuoteRequestScreenEnum.INTRO
              : QuoteRequestScreenEnum.QUESTION,
        },
        questionnaire: state.questionnaire,
      };
    }

    case 'toggle-quote-request-addons': {
      return merge<Readonly<IQuoteRequest>, RecursivePartial<IQuoteRequest>>(
        state,
        {
          instantBooking: {
            addons: state.instantBooking.addons.some((a) => a.slug === action.value.slug)
              ? state.instantBooking.addons.filter((a) => a.slug !== action.value.slug)
              : [...state.instantBooking.addons, action.value],
          },
        },
        { arrayMerge: (_, sourceArray = []) => sourceArray }
      );
    }
    case 'update-quote-request-addons': {
      return merge<Readonly<IQuoteRequest>, RecursivePartial<IQuoteRequest>>(
        state,
        {
          instantBooking: {
            addons: state.instantBooking.addons.map((a) =>
              a.slug === action.value.slug ? { ...a, ...action.value } : a
            ),
          },
        },
        { arrayMerge: (_, sourceArray = []) => sourceArray, clone: false }
      );
    }
    case 'update-quote-request-state': {
      return merge<Readonly<IQuoteRequest>, RecursivePartial<IQuoteRequest>>(
        state,
        action.value,
        action.mergeOptions
      );
    }
    default:
      return state;
  }
}

const QuoteRequestProvider: React.FC<{
  children: React.ReactNode;
  defaultValues?: RecursivePartial<IQuoteRequest>;
}> = (props) => {
  const { children, defaultValues = emptyObject } = props;

  const [state, dispatch] = useReducer(
    quoteRequestReducer,
    merge(quoteRequestInitState, {
      ...defaultValues,
      ...(defaultValues.questionnaire?.introText || defaultValues.questionnaire?.introTitle
        ? { modal: { currentView: QuoteRequestScreenEnum.INTRO } }
        : {}),
    })
  );

  useEffect(() => {
    dispatch({
      mergeOptions: { arrayMerge: (_, sourceArray = emptyArray) => sourceArray },
      type: 'update-quote-request-state',
      value: defaultValues,
    });
  }, [defaultValues]);

  const value = useMemo<[IQuoteRequest, Dispatch<TAction>]>(() => [state, dispatch], [state]);

  return <QuoteRequestContext.Provider value={value}>{children}</QuoteRequestContext.Provider>;
};

function useQuoteRequest(): [
  IQuoteRequest,
  {
    initInstantBookingStep: (step: string) => void;
    resetAnswerGroupId: () => void;
    resetState: () => void;
    selectDate: (date: string) => void;
    selectInstantBookingPackage: (packageId: string) => void;
    selectTime: (time: string) => void;
    sendAnswer: (questionnaireId: string, body: unknown) => Promise<TResponse<unknown>>;
    setAnswer: (index: number, answer: unknown) => void;
    setAnswerGroupId: (answerGroupId: string) => void;
    setErrors: (errors: QuoteRequestErrors) => void;
    setInstantBookingClientInfo: (clientInfo: Record<string, string>) => void;
    setInstantBookingSignatures: (contracts: IQuoteRequestInstantBooking['contracts']) => void;
    setInstantBookingStep: (nextStep: string, prevStep?: string) => void;
    setTimezoneName: (timezoneName: string) => void;
    showNextQuestion: () => void;
    showPrevQuestion: () => void;
    showQuestion: (index: number) => void;
    showQuoteRequestModal: (trigger?: string) => void;
    showView: (view: string) => void;
    toggleAddon: (addon: OrderItemResponse) => void;
    updateInstantBookingAddon: (addon: Partial<OrderItemResponse>) => void;
  },
] {
  const context = useContext(QuoteRequestContext);
  const { hideMessage, showErrorMessageFromResponse } = useInfoMessage();

  const { post: sendAnswerPost } = useFetch();

  if (!context) {
    throw new Error(`useQuoteRequest must be used within a QuoteRequestProvider`);
  }

  const [state, dispatch] = context;

  const hideModal = useCallback(() => {
    hideMessage();
    dispatch({ type: 'hide-modal' });
  }, [dispatch, hideMessage]);

  const initInstantBookingStep = useCallback(
    (step: string) =>
      dispatch({
        type: 'update-quote-request-state',
        value: { instantBooking: { step } },
      }),
    [dispatch]
  );

  const resetState = useCallback(() => dispatch({ type: 'reset-state' }), [dispatch]);

  const resetAnswerGroupId = useCallback(
    () => dispatch({ type: 'update-quote-request-state', value: { modal: { answerGroupId: '' } } }),
    [dispatch]
  );

  const setAnswer = useCallback(
    (index: number, answer: unknown) => {
      const answers = state.modal.answers.slice();
      answers[index] = answer;

      hideMessage();

      dispatch({
        mergeOptions: { arrayMerge: (_, sourceArray = emptyArray) => sourceArray },
        type: 'update-quote-request-state',
        value: { modal: { answers } },
      });
    },
    [dispatch, hideMessage, state.modal.answers]
  );

  const setAnswerGroupId = useCallback(
    (answerGroupId: string) =>
      dispatch({ type: 'update-quote-request-state', value: { modal: { answerGroupId } } }),
    [dispatch]
  );

  const setErrors = useCallback(
    (errors: QuoteRequestErrors) => {
      dispatch({
        type: 'update-quote-request-state',
        value: { modal: { errors } },
      });
    },
    [dispatch]
  );

  const setInstantBookingClientInfo = useCallback(
    (clientInfo: Record<string, string>) => {
      dispatch({ type: 'update-quote-request-state', value: { instantBooking: { clientInfo } } });
    },
    [dispatch]
  );

  const setInstantBookingSignatures = useCallback(
    (contracts: IQuoteRequestInstantBooking['contracts']) => {
      dispatch({
        mergeOptions: { arrayMerge: (_, sourceArray = emptyArray) => sourceArray },
        type: 'update-quote-request-state',
        value: { instantBooking: { contracts } },
      });
    },
    [dispatch]
  );

  const setInstantBookingStep = useCallback(
    (nextStep: string, prevStep = '') => {
      if (prevStep) {
        dispatch({
          type: 'update-quote-request-state',
          value: { instantBooking: { step: nextStep, stepHistory: [prevStep] } },
        });
      } else {
        dispatch({
          mergeOptions: { arrayMerge: (_, sourceArray = emptyArray) => sourceArray },
          type: 'update-quote-request-state',
          value: {
            instantBooking: {
              step: nextStep,
              stepHistory: state.instantBooking.stepHistory.slice(0, -1),
            },
          },
        });
      }
    },
    [dispatch, state.instantBooking.stepHistory]
  );

  const setTimezoneName = useCallback(
    (timezoneName: string) =>
      dispatch({
        type: 'update-quote-request-state',
        value: { dateTimePicker: { selectedTimezoneName: timezoneName } },
      }),
    [dispatch]
  );

  const showQuestion = useCallback(
    (index: number) => {
      hideMessage();

      dispatch({
        type: 'update-quote-request-state',
        value: {
          modal: {
            currentView: QuoteRequestScreenEnum.QUESTION,
            isValidationOn: false,
            questionIndex: index,
          },
        },
      });
    },
    [dispatch, hideMessage]
  );

  const showNextQuestion = useCallback(() => {
    hideMessage();

    dispatch({
      type: 'update-quote-request-state',
      value: {
        modal: {
          isValidationOn: false,
          questionIndex: state.modal.questionIndex + 1,
        },
      },
    });
  }, [dispatch, hideMessage, state.modal.questionIndex]);

  const showPrevQuestion = useCallback(() => {
    hideMessage();

    dispatch({
      type: 'update-quote-request-state',
      value: {
        modal: {
          isValidationOn: false,
          questionIndex: Math.max(0, state.modal.questionIndex - 1),
        },
      },
    });
  }, [dispatch, hideMessage, state.modal.questionIndex]);

  const showQuoteRequestModal = useCallback(
    (trigger = 'cta') =>
      dispatch({
        mergeOptions: { arrayMerge: (_, sourceArray = emptyArray) => sourceArray },
        type: 'update-quote-request-state',
        value: merge(quoteRequestInitState, {
          modal: {
            ...state.modal,
            currentView: QuoteRequestScreenEnum.QUESTION,
            isShown: true,
            isTriggeredByWidget: trigger === 'widget',
            questionIndex: 0,
            trigger,
          },
          questionnaire: state.questionnaire,
        }),
      }),
    [dispatch, state.modal, state.questionnaire]
  );

  const showView = useCallback(
    (view: string) => {
      hideMessage();

      dispatch({
        type: 'update-quote-request-state',
        value: { modal: { currentView: view } },
      });
    },
    [dispatch, hideMessage]
  );

  const selectDate = useCallback(
    (date: string) =>
      dispatch({
        type: 'update-quote-request-state',
        value: { dateTimePicker: { selectedDate: date } },
      }),
    [dispatch]
  );

  const selectInstantBookingPackage = useCallback(
    (packageId: string) => {
      hideMessage();

      dispatch({
        mergeOptions: { arrayMerge: (_, sourceArray = emptyArray) => sourceArray },
        type: 'update-quote-request-state',
        value: {
          instantBooking: {
            addons: emptyArray,
            contracts: emptyArray,
            selectedPackageId: packageId,
          },
        },
      });
    },
    [dispatch, hideMessage]
  );

  const selectTime = useCallback(
    (time: string) =>
      dispatch({
        type: 'update-quote-request-state',
        value: { dateTimePicker: { selectedTime: time } },
      }),
    [dispatch]
  );

  const sendAnswer = useCallback(
    (questionnaireId: string, body: unknown) => {
      if (state.isPreview) {
        return Promise.resolve();
      }

      const promise = sendAnswerPost(`/api/questionnaires/${questionnaireId}/answers`, body);

      promise.then((res) => {
        if (res.status === AsyncStatusEnum.SUCCESS) {
          const { answer } = res.data;

          dispatch({
            type: 'update-quote-request-state',
            value: { modal: { sentAnswers: { [answer.questionId]: answer } } },
          });
        }

        if (res.status === AsyncStatusEnum.ERROR) {
          showErrorMessageFromResponse(res);
          return;
        }
      });

      return promise;
    },
    [dispatch, sendAnswerPost, showErrorMessageFromResponse, state.isPreview]
  );

  const toggleAddon = useCallback(
    (addon: OrderItemResponse) => {
      dispatch({
        type: 'toggle-quote-request-addons',
        value: addon,
      });
    },
    [dispatch]
  );

  const updateInstantBookingAddon = useCallback(
    (addon: OrderItemResponse) => {
      dispatch({ type: 'update-quote-request-addons', value: addon });
    },
    [dispatch]
  );

  return useMemo(
    () => [
      state,
      {
        hideModal,
        initInstantBookingStep,
        resetAnswerGroupId,
        resetState,
        selectDate,
        selectInstantBookingPackage,
        selectTime,
        sendAnswer,
        setAnswer,
        setAnswerGroupId,
        setErrors,
        setInstantBookingClientInfo,
        setInstantBookingSignatures,
        setInstantBookingStep,
        setTimezoneName,
        showNextQuestion,
        showPrevQuestion,
        showQuestion,
        showQuoteRequestModal,
        showView,
        toggleAddon,
        updateInstantBookingAddon,
      },
    ],
    [
      hideModal,
      initInstantBookingStep,
      resetAnswerGroupId,
      resetState,
      selectDate,
      selectInstantBookingPackage,
      selectTime,
      sendAnswer,
      setAnswer,
      setAnswerGroupId,
      setErrors,
      setInstantBookingClientInfo,
      setInstantBookingSignatures,
      setInstantBookingStep,
      setTimezoneName,
      showNextQuestion,
      showPrevQuestion,
      showQuestion,
      showQuoteRequestModal,
      showView,
      state,
      toggleAddon,
      updateInstantBookingAddon,
    ]
  );
}

function useSelectedPackage() {
  const [
    {
      instantBooking,
      questionnaire: { owner },
    },
  ] = useQuoteRequest();

  const { data: bookingPackages } = useBookingPackages(owner.id);

  return useMemo(
    () => bookingPackages[instantBooking.selectedPackageId],
    [bookingPackages, instantBooking.selectedPackageId]
  );
}

function useQuestions() {
  const [{ questionnaire }] = useQuoteRequest();

  return useMemo(() => {
    const instantBookingQuestion = questionnaire.questions.find(
      (q) => q.type === QuestionType.BOOKING_PACKAGE
    );

    if (instantBookingQuestion) {
      return questionnaire.questions.filter((q) => q.type !== QuestionType.PERSONAL_INFO);
    }

    return questionnaire.questions;
  }, [questionnaire.questions]);
}

function useCurrentQuestion<T = IQuestion>() {
  const [{ modal }] = useQuoteRequest();
  const { questionIndex } = modal;

  const questions = useQuestions();

  return useMemo(() => questions[questionIndex] as T, [questionIndex, questions]);
}

function useAnswer<T>(index: number) {
  const [{ modal }] = useQuoteRequest();
  const { answers } = modal;

  const data = answers[index] as T;

  return useMemo(() => data, [data]);
}

function useQuestionnaireSubmit() {
  const [pending, { setFalse: stopPending, setTrue: startPending }] = useToggle();

  const { showErrorMessageFromResponse } = useInfoMessage();

  const [
    {
      isPreview,
      modal: { answerGroupId },
      questionnaire: { id, redirectUrl, settings },
    },
    { resetState, sendAnswer, showView },
  ] = useQuoteRequest();

  const handleSubmit = useCallback(() => {
    if (settings.isCustomConfirmationEnabled) {
      if (isPreview) {
        resetState();
      } else {
        startPending();

        sendAnswer(id, { answerGroupId, payload: 'SUBMIT' })
          .then((res) => {
            if (res.status === AsyncStatusEnum.SUCCESS) {
              window.location.href = redirectUrl;
            }

            if (res.status === AsyncStatusEnum.ERROR) {
              showErrorMessageFromResponse(res);
            }
          })
          .finally(stopPending);
      }
    } else {
      showView(QuoteRequestScreenEnum.OUTRO);
    }
  }, [
    answerGroupId,
    id,
    isPreview,
    redirectUrl,
    resetState,
    sendAnswer,
    settings.isCustomConfirmationEnabled,
    showErrorMessageFromResponse,
    showView,
    startPending,
    stopPending,
  ]);

  return { pending, submitQuestionnaireForm: handleSubmit };
}

export {
  QuoteRequestProvider,
  useAnswer,
  useCurrentQuestion,
  useQuestionnaireSubmit,
  useQuestions,
  useQuoteRequest,
  useSelectedPackage,
};
