import React from 'react';

import { IIconProps } from './types';

const GearIcon: React.FC<IIconProps> = ({ width = 20, className = '', color = 'currentColor' }) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M10 1.667a.833.833 0 00-.833.833v.145a2.208 2.208 0 01-1.339 2.02.833.833 0 01-.22.061 2.208 2.208 0 01-2.274-.505l-.007-.007-.05-.05a.836.836 0 00-1.18 0 .833.833 0 000 1.18l.057.057a2.208 2.208 0 01.452 2.411 2.208 2.208 0 01-2.012 1.421H2.5a.833.833 0 000 1.667h.145a2.208 2.208 0 012.02 1.334 2.208 2.208 0 01-.444 2.432l-.007.007-.05.05a.835.835 0 000 1.18.833.833 0 001.18 0l.057-.057a2.209 2.209 0 012.411-.452 2.208 2.208 0 011.421 2.011v.095a.833.833 0 001.667 0v-.145a2.208 2.208 0 011.334-2.02 2.209 2.209 0 012.432.444l.007.007.05.05a.836.836 0 00.909.181.834.834 0 00.27-.18v-.002a.832.832 0 000-1.179l-.056-.057a2.209 2.209 0 01-.444-2.431 2.209 2.209 0 012.02-1.335h.078a.834.834 0 000-1.666h-.145a2.208 2.208 0 01-2.021-1.339.837.837 0 01-.06-.22 2.208 2.208 0 01.505-2.274l.007-.007.05-.05a.837.837 0 00.181-.909.834.834 0 00-.18-.27h-.002a.833.833 0 00-1.179 0l-.057.056a2.208 2.208 0 01-2.431.444 2.208 2.208 0 01-1.335-2.02V2.5A.834.834 0 0010 1.667zM8.232.732A2.5 2.5 0 0112.5 2.5v.073a.542.542 0 00.328.494l.008.004a.542.542 0 00.595-.106l.046-.046a2.5 2.5 0 113.537 3.537l-.046.046a.541.541 0 00-.106.595.83.83 0 01.054.166.543.543 0 00.444.237h.14a2.5 2.5 0 010 5h-.073a.542.542 0 00-.494.328l-.004.008a.542.542 0 00.106.595l.046.046a2.5 2.5 0 010 3.538l-.59-.59.59.589a2.5 2.5 0 01-3.537 0l-.046-.046a.542.542 0 00-.595-.106l-.008.004a.542.542 0 00-.328.494v.14a2.5 2.5 0 01-5 0v-.063a.542.542 0 00-.355-.488.932.932 0 01-.048-.02.542.542 0 00-.595.106l-.046.046a2.501 2.501 0 11-3.537-3.537l.046-.046a.542.542 0 00.106-.595l-.004-.008a.542.542 0 00-.494-.328H2.5a2.5 2.5 0 110-5h.063a.542.542 0 00.488-.355.831.831 0 01.02-.048.542.542 0 00-.106-.595l-.046-.046a2.5 2.5 0 113.537-3.537l.046.046a.542.542 0 00.595.106.834.834 0 01.166-.054.542.542 0 00.237-.444V2.5A2.5 2.5 0 018.232.732zM10 8.333a1.667 1.667 0 100 3.334 1.667 1.667 0 000-3.334zM6.667 10a3.333 3.333 0 116.666 0 3.333 3.333 0 01-6.666 0z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
export { GearIcon };
