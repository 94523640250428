import merge from 'deepmerge';
import { FormikErrors } from 'formik';
import { AnyObjectSchema, ValidationError } from 'yup';

import { getUpdatedObj } from '@bloom/library/utils/misc';

interface IValidateFormReturnType<V> {
  errorMessages: string[];
  errors: FormikErrors<V>;
}

export function validateForm<V>(
  values: V,
  validationSchema: AnyObjectSchema
): IValidateFormReturnType<V> {
  let errors = {};
  const errorMessages: string[] = [];

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (e) {
    if (e instanceof ValidationError) {
      errors = e.inner.reduce((acc: FormikErrors<V>, item) => {
        const path = (item?.path || '').replace(/\[(.*?)\]/g, '.$1');
        errorMessages.push(item.message);
        const errorObj = getUpdatedObj({}, path, item.message);
        return merge(acc as Record<string, unknown>, errorObj) as FormikErrors<V>;
      }, {} as FormikErrors<V>);
    }
  }

  return {
    errorMessages,
    errors,
  };
}
