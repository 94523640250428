import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { TimezoneResponse } from '@bloom/codegen/models/TimezoneResponse';

import { ISelectOption } from '@bloom/library/components/Form/SelectV2/types';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useFetch } from '@bloom/library/components/hooks/useFetch';

import { SharedQueryKeyEnum } from './interface';
import { useAccount } from './useAccounts';

export const useTimezones = () => {
  const { get, status } = useFetch();

  const { account } = useAccount();

  const currentTimezoneName = useMemo(
    () =>
      // timezone can be null https://app.clickup.com/t/8685h2kgr
      account?.settings?.timezone?.name ||
      (typeof Intl === 'undefined' ? '' : Intl.DateTimeFormat().resolvedOptions().timeZone),
    [account?.settings?.timezone?.name]
  );

  async function fetchTimezones(): Promise<Array<ISelectOption>> {
    const response = await get<{ timezones: Array<TimezoneResponse> }>('/api/timezones');
    if (response.status === AsyncStatusEnum.SUCCESS) {
      return response.data.timezones.map((t) => ({
        label: `${t.name.replace('_', ' ')} (${t.offset} ${t.abbreviation})`,
        value: t.name,
      }));
    }
    return [];
  }

  const queryResult = useQuery<ReturnType<typeof fetchTimezones>, null, Array<ISelectOption>>({
    queryFn: fetchTimezones,
    queryKey: [SharedQueryKeyEnum.TIMEZONES_LIST],
  });

  return useMemo(
    () => ({ currentTimezoneName, status, timezoneOptions: queryResult.data || [] }),
    [currentTimezoneName, queryResult.data, status]
  );
};
