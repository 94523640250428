import React from 'react';

import { twMerge } from 'tailwind-merge';

import {
  Text,
  TextComponentType,
  TextPropsType,
  TextSizeEnum,
  TextVariantEnum,
} from '@bloom/ui/components/Typography/Text';

const NoteText: TextComponentType = React.forwardRef(
  <C extends React.ElementType = 'p'>(props: Partial<TextPropsType<C>>, forwardedRef) => {
    const { className, size, variant, ...restProps } = props;
    return (
      <Text
        className={twMerge('text-grey', className)}
        ref={forwardedRef}
        size={TextSizeEnum.XS}
        variant={TextVariantEnum.REGULAR}
        {...restProps}
      />
    );
  }
);

export { NoteText };
