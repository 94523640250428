import React from 'react';

import { IIconProps } from './types';

const MinusIcon: React.FC<IIconProps> = ({
  width = 20,
  className = '',
  color = 'currentColor',
}) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M3.333 10c0-.46.373-.833.834-.833h11.666a.833.833 0 010 1.666H4.167A.833.833 0 013.333 10z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
export { MinusIcon };
