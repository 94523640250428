import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const AlignLeftIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => (
  <svg className={className} height={width} viewBox="0 0 16 16" width={width}>
    <path
      clipRule="evenodd"
      d="M1.333 4c0-.368.299-.666.667-.666h12a.667.667 0 0 1 0 1.333H2a.667.667 0 0 1-.667-.666Zm0 2.667c0-.368.299-.666.667-.666h9.334a.667.667 0 1 1 0 1.333H2a.667.667 0 0 1-.667-.667Zm0 2.667c0-.368.299-.667.667-.667h12a.667.667 0 1 1 0 1.334H2a.667.667 0 0 1-.667-.667Zm0 2.667c0-.368.299-.667.667-.667h9.334a.667.667 0 0 1 0 1.333H2a.667.667 0 0 1-.667-.666Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export { AlignLeftIcon };
