import React from 'react';

import { IIconProps } from './types';

const UserIconV2: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 20,
}) => {
  return (
    <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
      <path
        clipRule="evenodd"
        d="M10 1.667A4.167 4.167 0 1010 10a4.167 4.167 0 000-8.333zM7.5 5.833a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M6.667 11.667A4.167 4.167 0 002.5 15.833V17.5a.833.833 0 101.667 0v-1.667a2.5 2.5 0 012.5-2.5h6.666a2.5 2.5 0 012.5 2.5V17.5a.833.833 0 101.667 0v-1.667a4.167 4.167 0 00-4.167-4.166H6.667z"
        fill={color}
      />
    </svg>
  );
};

export { UserIconV2 };
