import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useFetch } from '@bloom/library/components/hooks/useFetch';

import { SharedQueryKeyEnum } from './interface';

import { IAvailabilityExceptionPublic } from '../Availability/types';

export const useAggregatedAvailabilityExceptions = (props: {
  endDate?: string;
  questionId?: string;
  startDate?: string;
  timezone: string;
  userId: string;
}) => {
  const { endDate = '', questionId, startDate = '', timezone, userId } = props;
  const { get } = useFetch();

  const searchParams = new URLSearchParams({ tz: timezone });

  if (startDate && endDate) {
    searchParams.set('startDate', startDate);
    searchParams.set('endDate', endDate);
  }

  if (questionId) {
    searchParams.set('questionId', questionId);
  }

  const queryString = searchParams.toString();

  async function fetchAvailabilityExceptions(): Promise<{
    [date: string]: IAvailabilityExceptionPublic;
  }> {
    const response = await get<{ dailySchedules: Array<IAvailabilityExceptionPublic> }>(
      `/api/users/${userId}/aggregated-availability?${queryString}`
    );

    if (response.status === AsyncStatusEnum.SUCCESS) {
      return response.data.dailySchedules.reduce(
        (obj, schedule) => ({ ...obj, [schedule.date]: schedule }),
        {}
      );
    }
    return {};
  }

  const { data: availabilityExceptions, isFetching } = useQuery<{
    [date: string]: IAvailabilityExceptionPublic;
  }>({
    queryFn: fetchAvailabilityExceptions,
    queryKey: [
      SharedQueryKeyEnum.AGGREGATED_AVAILABILITY_EXCEPTIONS,
      endDate,
      questionId,
      startDate,
      timezone,
      userId,
    ],
    staleTime: 60000,
  });

  return useMemo(
    () => ({ availabilityExceptions, isFetching }),
    [availabilityExceptions, isFetching]
  );
};
