import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

export default class Dropzone extends Component {
  static propTypes = {
    accept: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    disabledClassName: PropTypes.string,
    draggingClassName: PropTypes.string,
    isClickDisabled: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isMultiple: PropTypes.bool,
    isUploadingDisabled: PropTypes.bool,
    onUpload: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
    // It only disables uploading,
    // event heandlers continue to work.
    // It disables this component completely,
    // it basically turns it into a static markup.
  };

  static defaultProps = {
    accept: undefined,
    isDisabled: false,
    isMultiple: false,
    isUploadingDisabled: false,
  };

  constructor(props) {
    super(props);
    this.state = { isDragging: false };
  }

  startDragging = () => {
    const { isDragging } = this.state;
    if (!isDragging) {
      this.setState({ isDragging: true });
    }
  };

  stopDragging = () => {
    const { isDragging } = this.state;
    if (isDragging) {
      this.setState({ isDragging: false });
    }
  };

  handleDrop = (e) => {
    const { isDisabled, isUploadingDisabled } = this.props;
    if (!isDisabled) {
      e.preventDefault();
      this.stopDragging();

      // Don't allow to upload if uploading is disabled.
      if (!isUploadingDisabled) {
        this.props.onUpload(e.dataTransfer.files);
      }
    }
  };

  handleDragLeave = () => {
    this.stopDragging();
  };

  handleDragOver = (e) => {
    const { isDisabled } = this.props;
    if (!isDisabled) {
      // It's important to specify e.preventDefault()
      // in both onDragOver and onDrop to prevent
      // default browser behavior.
      e.preventDefault();
      // Prevent dnd libraries interfering with Dropzone.
      e.stopPropagation();
      this.startDragging();
    }
  };

  handleClick = () => {
    const { isClickDisabled, isDisabled, isUploadingDisabled } = this.props;

    if (!isClickDisabled && !isDisabled && !isUploadingDisabled) {
      this.fileInput.click();
    }
  };

  handleFileSelect = (e) => {
    this.props.onUpload(e.target.files);

    // reset input state to load the same images
    this.fileInput.value = '';
  };

  render() {
    const { accept, children, isMultiple, isDisabled, isUploadingDisabled, tabIndex } = this.props;
    const { className, draggingClassName, disabledClassName } = this.props;
    const { isDragging } = this.state;
    return (
      <div
        className={twMerge(
          'border-regular relative cursor-pointer overflow-hidden rounded-sm border',
          isDragging ? '' : 'border-dashed',
          isDragging && !isDisabled && !isUploadingDisabled
            ? `${draggingClassName} border-solid after:absolute after:inset-0 after:z-10`
            : '',
          isDisabled || isUploadingDisabled ? `${disabledClassName} cursor-default` : '',

          className
        )}
        data-testid={this.props['data-testid'] || 'dropzone'}
        onClick={this.handleClick}
        onDragLeave={this.handleDragLeave}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
      >
        <input
          accept={accept}
          className="sr-only"
          multiple={isMultiple}
          onChange={this.handleFileSelect}
          ref={(node) => (this.fileInput = node)}
          style={{ clip: 'rect(0 0 0 0)' }}
          tabIndex={tabIndex}
          type="file"
        />
        {children}
      </div>
    );
  }
}
