import { useQuery } from '@tanstack/react-query';

import { CustomFontResponse } from '@bloom/codegen/models/CustomFontResponse';

import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useFetch } from '@bloom/library/components/hooks/useFetch';

import { SharedQueryKeyEnum } from './interface';

export function usePublicCustomFonts(userId: string) {
  const { get } = useFetch();

  async function fetchCustomFont() {
    const response = await get<{ customFonts: Readonly<Array<CustomFontResponse>> }>(
      `/api/public/${userId}/custom-fonts`
    );

    if (response.status === AsyncStatusEnum.SUCCESS) {
      return response.data.customFonts;
    }

    return [];
  }

  return useQuery({
    queryFn: fetchCustomFont,
    queryKey: [SharedQueryKeyEnum.PUBLIC_CUSTOM_FONTS, userId],
  });
}
