import { QuestionType } from '@bloom/codegen/models/QuestionType';

import { AlignLeftIcon } from '@bloom/ui/components/Icons/AlignLeft';
import { ButtonIcon } from '@bloom/ui/components/Icons/Button';
import { ListIcon } from '@bloom/ui/components/Icons/List';

import { CalendarIconV2 } from '@bloom/library/components/Icon/CalendarV2';
import { FileIcon } from '@bloom/library/components/Icon/File';
import { GearIcon } from '@bloom/library/components/Icon/Gear';
import { InstantBookingIcon } from '@bloom/library/components/Icon/InstantBooking';
import { PinIconV2 } from '@bloom/library/components/Icon/PinV2';
import { PlusIcon } from '@bloom/library/components/Icon/Plus';
import { SortSwapIcon } from '@bloom/library/components/Icon/SortSwap';
import { UserIconV2 } from '@bloom/library/components/Icon/UserV2';

export const typeNames = {
  [QuestionType.ADDRESS]: 'Physical Address',
  [QuestionType.ATTACHMENT]: 'File Upload',
  [QuestionType.BOOKING_PACKAGE]: 'Packages & add-ons',
  [QuestionType.BOOLEAN]: 'Yes/No',
  [QuestionType.CONTENT]: 'Content',
  [QuestionType.DATE]: 'Date',
  [QuestionType.MULTIPLE_CHOICE]: 'Multiple Choice',
  [QuestionType.ONE_LINE_TEXT]: 'Text Field',
  [QuestionType.PERSONAL_INFO]: 'Client Info',
  [QuestionType.SPECIALTY]: 'Specialty',
  [QuestionType.TEXTUAL]: 'Short Paragraph',
};

export const questionTypes = [
  {
    description: 'Provide a set of options.',
    displayName: typeNames[QuestionType.MULTIPLE_CHOICE],
    type: QuestionType.MULTIPLE_CHOICE,
  },
  {
    description: 'Allow your clients to select a date and time.',
    displayName: typeNames[QuestionType.DATE],
    type: QuestionType.DATE,
  },
  {
    description: 'Get contact information.',
    displayName: typeNames[QuestionType.PERSONAL_INFO],
    type: QuestionType.PERSONAL_INFO,
  },
  {
    description: 'Give space to type a little.',
    displayName: typeNames[QuestionType.TEXTUAL],
    type: QuestionType.TEXTUAL,
  },
  {
    description: 'Give a simple option between yes or no.',
    displayName: typeNames[QuestionType.BOOLEAN],
    type: QuestionType.BOOLEAN,
  },
  {
    description: 'Give a simple text field.',
    displayName: typeNames[QuestionType.ONE_LINE_TEXT],
    type: QuestionType.ONE_LINE_TEXT,
  },
  {
    description: 'Allow your client to set a specialty.',
    displayName: typeNames[QuestionType.SPECIALTY],
    type: QuestionType.SPECIALTY,
  },
  {
    description: 'Allow your clients to upload a file.',
    displayName: typeNames[QuestionType.ATTACHMENT],
    type: QuestionType.ATTACHMENT,
  },
  {
    description: 'Add content without asking questions.',
    displayName: typeNames[QuestionType.CONTENT],
    type: QuestionType.CONTENT,
  },
  {
    description: 'Collect a physical address.',
    displayName: typeNames[QuestionType.ADDRESS],
    type: QuestionType.ADDRESS,
  },
];

export const questionTypeIconMap = {
  [QuestionType.ADDRESS]: PinIconV2,
  [QuestionType.ATTACHMENT]: FileIcon,
  [QuestionType.BOOKING_PACKAGE]: InstantBookingIcon,
  [QuestionType.BOOLEAN]: SortSwapIcon,
  [QuestionType.CONTENT]: PlusIcon,
  [QuestionType.DATE]: CalendarIconV2,
  [QuestionType.MULTIPLE_CHOICE]: ListIcon,
  [QuestionType.ONE_LINE_TEXT]: ButtonIcon,
  [QuestionType.PERSONAL_INFO]: UserIconV2,
  [QuestionType.SPECIALTY]: GearIcon,
  [QuestionType.TEXTUAL]: AlignLeftIcon,
};
