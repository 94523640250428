import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

export const ListIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 20,
}) => (
  <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
    <path
      clipRule="evenodd"
      d="M4.66699 12C4.66699 11.6318 4.96547 11.3333 5.33366 11.3333H14.0003C14.3685 11.3333 14.667 11.6318 14.667 12C14.667 12.3682 14.3685 12.6667 14.0003 12.6667H5.33366C4.96547 12.6667 4.66699 12.3682 4.66699 12Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.66699 8C4.66699 7.63181 4.96547 7.33333 5.33366 7.33333H14.0003C14.3685 7.33333 14.667 7.63181 14.667 8C14.667 8.36819 14.3685 8.66667 14.0003 8.66667H5.33366C4.96547 8.66667 4.66699 8.36819 4.66699 8Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.66699 4C4.66699 3.63181 4.96547 3.33333 5.33366 3.33333H14.0003C14.3685 3.33333 14.667 3.63181 14.667 4C14.667 4.36819 14.3685 4.66667 14.0003 4.66667H5.33366C4.96547 4.66667 4.66699 4.36819 4.66699 4Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M1.16699 4C1.16699 3.53976 1.54009 3.16667 2.00033 3.16667H2.00699C2.46723 3.16667 2.84033 3.53976 2.84033 4C2.84033 4.46024 2.46723 4.83333 2.00699 4.83333H2.00033C1.54009 4.83333 1.16699 4.46024 1.16699 4ZM1.16699 8C1.16699 7.53976 1.54009 7.16667 2.00033 7.16667H2.00699C2.46723 7.16667 2.84033 7.53976 2.84033 8C2.84033 8.46024 2.46723 8.83333 2.00699 8.83333H2.00033C1.54009 8.83333 1.16699 8.46024 1.16699 8ZM1.16699 12C1.16699 11.5398 1.54009 11.1667 2.00033 11.1667H2.00699C2.46723 11.1667 2.84033 11.5398 2.84033 12C2.84033 12.4602 2.46723 12.8333 2.00699 12.8333H2.00033C1.54009 12.8333 1.16699 12.4602 1.16699 12Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
