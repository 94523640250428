import React from 'react';

import { IIconProps } from './types';

const PinIconV2: React.FC<IIconProps> = ({
  width = 20,
  className = '',
  color = 'currentColor',
}) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M10.0003 1.66667C8.23222 1.66667 6.53652 2.36905 5.28628 3.61929C4.03604 4.86953 3.33366 6.56522 3.33366 8.33333C3.33366 10.915 5.01344 13.4202 6.85529 15.3644C7.75917 16.3185 8.66588 17.0996 9.34756 17.6424C9.60125 17.8444 9.82284 18.0127 10.0003 18.1438C10.1778 18.0127 10.3994 17.8444 10.6531 17.6424C11.3348 17.0996 12.2415 16.3185 13.1454 15.3644C14.9872 13.4202 16.667 10.915 16.667 8.33333C16.667 6.56522 15.9646 4.86953 14.7144 3.61929C13.4641 2.36905 11.7684 1.66667 10.0003 1.66667ZM10.0003 19.1667C9.53808 19.86 9.53762 19.8597 9.53762 19.8597L9.53524 19.8582L9.52969 19.8544L9.51059 19.8415C9.49437 19.8305 9.47122 19.8147 9.44162 19.7942C9.38243 19.7532 9.29738 19.6934 9.19015 19.616C8.97578 19.4612 8.67229 19.2352 8.30935 18.9462C7.58477 18.3692 6.61648 17.5357 5.64537 16.5106C3.73721 14.4965 1.66699 11.585 1.66699 8.33333C1.66699 6.1232 2.54497 4.00358 4.10777 2.44078C5.67057 0.877974 7.79019 0 10.0003 0C12.2105 0 14.3301 0.877974 15.8929 2.44078C17.4557 4.00358 18.3337 6.1232 18.3337 8.33333C18.3337 11.585 16.2634 14.4965 14.3553 16.5106C13.3842 17.5357 12.4159 18.3692 11.6913 18.9462C11.3284 19.2352 11.0249 19.4612 10.8105 19.616C10.7033 19.6934 10.6182 19.7532 10.559 19.7942C10.5294 19.8147 10.5063 19.8305 10.4901 19.8415L10.471 19.8544L10.4654 19.8582L10.4636 19.8593C10.4636 19.8593 10.4626 19.86 10.0003 19.1667ZM10.0003 19.1667L10.4626 19.86C10.1827 20.0467 9.81754 20.0464 9.53762 19.8597L10.0003 19.1667Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.0003 7.29167C10.5756 7.29167 11.042 7.75804 11.042 8.33333V8.34167C11.042 8.91696 10.5756 9.38333 10.0003 9.38333C9.42503 9.38333 8.95866 8.91696 8.95866 8.34167V8.33333C8.95866 7.75804 9.42503 7.29167 10.0003 7.29167Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export { PinIconV2 };
