import React, { useCallback, useEffect } from 'react';

import { format, parseISO } from 'date-fns';
import { twMerge } from 'tailwind-merge';

import { LocaleSettingResponse } from '@bloom/codegen/models/LocaleSettingResponse';

import { Combobox } from '@bloom/ui/components/Combobox';
import { CustomScroll } from '@bloom/ui/components/CustomScroll';
import { DatePicker } from '@bloom/ui/components/DatePicker';
import { defaultLocaleSettings } from '@bloom/ui/hooks/useLocaleSettings';
import { doNothing, emptyArray } from '@bloom/ui/utils/empty-value';

import {
  IAvailabilityExceptionPublic,
  IAvailabilitySettings,
} from '@bloom/library/components/Availability/types';
import { useMoment } from '@bloom/library/components/hooks/useMoment';
import Loader from '@bloom/library/components/Loader';
import { H2 } from '@bloom/library/components/Typography/H2';

import { useTimezones } from '../hooks/useTimezones';

const noAvailableSpotsPlaceholder =
  'Unfortunately, there are no available spots on this day. Please try a different date.';

const HourSelector: React.FC<{
  displayValue: string;
  isChecked: boolean;
  onClick: (time: string) => void;
  value: string;
}> = (props) => {
  const { displayValue, isChecked, onClick, value } = props;

  function handleClick() {
    onClick(`${value.slice(0, 2)}:${value.slice(2)}`);
  }

  return (
    <button
      className={twMerge(
        'w-full rounded-sm px-4 py-3 text-center font-medium',
        'border-black-15 dark:border-white-15 border',
        isChecked
          ? 'text-cta-white dark:text-cta-black bg-black dark:bg-white'
          : 'hover:bg-black-5 hover:dark:bg-white-5'
      )}
      data-testid="time-select-item"
      onClick={handleClick}
    >
      {displayValue}
    </button>
  );
};

type IProps = {
  availabilityExceptions: {
    [date: string]: IAvailabilityExceptionPublic;
  } | null;
  availabilitySettings: IAvailabilitySettings | null;
  bookingDuration?: number;
  className?: string;
  defaultMonth?: Date;
  filterByAvailability?: boolean;
  header?: string;
  isAvailabilityExceptionsLoading: boolean;
  localeSettings: LocaleSettingResponse;
  onDateSelect?: (date: string) => void;
  onMonthChange?: (date: Date) => void;
  onTimeSelect?: (time: string) => void;
  onTimezoneSelect?: (timezoneName: string) => void;
  placeholder?: string;
  selectedDate?: string;
  selectedTime?: string;
  selectedTimezone?: string;
};

const AvailabilityDateTimePicker: React.FC<IProps> = (props) => {
  const {
    availabilityExceptions,
    availabilitySettings,
    bookingDuration: bookingDurationProp = 0,
    className,
    defaultMonth,
    filterByAvailability = true,
    header = '',
    isAvailabilityExceptionsLoading,
    localeSettings = defaultLocaleSettings,
    onDateSelect = doNothing,
    onMonthChange = doNothing,
    onTimeSelect = doNothing,
    onTimezoneSelect = doNothing,
    placeholder = 'No date selected',
    selectedDate = '',
    selectedTime = '',
    selectedTimezone = '',
  } = props;

  const { moment } = useMoment();

  const { timezoneOptions } = useTimezones();

  useEffect(() => {
    if (!selectedTimezone && window.Intl && typeof onTimezoneSelect === 'function') {
      onTimezoneSelect(Intl.DateTimeFormat().resolvedOptions().timeZone || '');
    }
  }, [onTimezoneSelect, selectedTimezone]);

  const getAvailableHours = useCallback(
    (obj: Record<'end' | 'start', string>): Array<Record<'displayValue' | 'value', string>> => {
      if (!obj.start && !obj.end) {
        console.error('Invalid availability interval object');
        return [];
      }

      if (!availabilitySettings) {
        return [];
      }

      const { start } = obj;
      const end = obj.end === '2359' ? '2400' : obj.end;

      const timeIncrement = Number(availabilitySettings.availabilityIncrement) || 30;
      const preBookingBuffer = Number(availabilitySettings.preBookingBuffer) || 0;
      const postBookingBuffer = Number(availabilitySettings.postBookingBuffer) || 0;

      let bookingDuration = bookingDurationProp;
      if (!bookingDuration) {
        bookingDuration = timeIncrement;
      }
      if (!filterByAvailability) {
        bookingDuration = timeIncrement;
      }

      const availableHours = [];
      let startDate = moment(start, 'hhmm').add(preBookingBuffer, 'm');
      const endDate = moment(end, 'hhmm').subtract(bookingDuration + postBookingBuffer, 'm');

      while (
        (startDate.isBefore(endDate) || startDate.isSame(endDate)) &&
        // Prevent accidental infinite loop.
        // Intervals cannot have more the 1440 available slots (24 hours with 1 min increment).
        availableHours.length < 1440
      ) {
        availableHours.push({
          displayValue: startDate.format(localeSettings.timeFormat),
          value: startDate.format('HHmm'),
        });

        startDate = startDate.add(timeIncrement, 'm');
      }

      return availableHours;
    },
    [
      availabilitySettings,
      bookingDurationProp,
      filterByAvailability,
      localeSettings.timeFormat,
      moment,
    ]
  );

  const preselectDateTime = useCallback(() => {
    let firstAvailableDate = '';
    let firstAvailableTime: Record<'displayValue' | 'value', string> | null = null;

    const today = moment().startOf('day');

    Object.entries(availabilityExceptions).some(([date, object]) => {
      if (object.schedule.length > 0 && !today.isAfter(date)) {
        let availableHours: Array<Record<'displayValue' | 'value', string>> = [];
        object.schedule.some((s) => {
          availableHours = getAvailableHours(s);
          return availableHours.length > 0;
        });

        if (availableHours.length > 0) {
          firstAvailableDate = date;
          firstAvailableTime = availableHours[0];
          return true;
        }
      }

      return false;
    });

    if (!!firstAvailableDate && !!firstAvailableTime) {
      if (typeof onDateSelect === 'function') {
        onDateSelect(firstAvailableDate);
      }

      onTimeSelect(`${firstAvailableTime.value.slice(0, 2)}:${firstAvailableTime.value.slice(2)}`);
    }
  }, [availabilityExceptions, getAvailableHours, moment, onDateSelect, onTimeSelect]);

  useEffect(() => {
    if (
      !isAvailabilityExceptionsLoading &&
      !selectedDate &&
      Object.keys(availabilityExceptions || {}).length > 0
    ) {
      preselectDateTime();
    }
  }, [availabilityExceptions, isAvailabilityExceptionsLoading, selectedDate, preselectDateTime]);

  function handleTimezoneSelect(_: string, value: string) {
    if (typeof onTimezoneSelect === 'function') {
      onTimezoneSelect(value);
    }
  }

  function renderAvailableHours(obj: Record<'end' | 'start', string>) {
    const availableHours = getAvailableHours(obj);

    return availableHours.map((h) => (
      <HourSelector
        displayValue={h.displayValue}
        isChecked={h.value === selectedTime}
        key={h.value}
        onClick={onTimeSelect}
        value={h.value}
      />
    ));
  }

  const availableIntervals = selectedDate
    ? availabilityExceptions?.[selectedDate]?.schedule || emptyArray
    : emptyArray;

  const isDateDisabledFn = useCallback(
    (date: Date) => {
      const { schedule } = availabilityExceptions?.[format(date, 'yyyy-MM-dd')] || {};

      if ((schedule || []).length === 0) {
        return true;
      }

      return false;
    },
    [availabilityExceptions]
  );

  return (
    <div
      className={twMerge(
        'relative mx-auto grid max-w-4xl grid-cols-1 gap-12 md:grid-cols-2',
        className
      )}
    >
      <div className="relative">
        {isAvailabilityExceptionsLoading ? <Loader /> : null}
        <DatePicker
          classNames={{
            month: twMerge(
              'w-full',
              isAvailabilityExceptionsLoading ? 'pointer-events-none opacity-0' : ''
            ),
            month_grid: 'w-full table-fixed border-collapse mt-6',
            months: 'flex-col flex w-full text-cta-black dark:text-cta-white',
          }}
          data-testid="date-picker"
          defaultMonth={defaultMonth}
          disabled={isDateDisabledFn}
          key={selectedDate}
          mode="single"
          onMonthChange={onMonthChange}
          onSelect={onDateSelect}
          selected={selectedDate ? parseISO(selectedDate) : undefined}
          showOutsideDays={true}
          startMonth={new Date()}
        />
      </div>

      <div className="flex w-full basis-96 flex-col gap-6" data-testid="time-picker">
        <H2 as="h3" className="md:hidden">
          {header}
        </H2>
        {typeof onTimezoneSelect === 'function' ? (
          <div>
            <Combobox
              data-testid="timezone-select"
              label="Timezone"
              menuItems={timezoneOptions}
              name="timezone"
              onChange={handleTimezoneSelect}
              placeholder="Select a Timezone"
              value={selectedTimezone || ''}
            />
          </div>
        ) : null}

        {availableIntervals.length > 0 ? (
          <CustomScroll style={{ maxHeight: 300 }}>
            <div
              className="grid gap-2"
              style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 120px))' }}
            >
              {availableIntervals.map((obj) => renderAvailableHours(obj))}
            </div>
          </CustomScroll>
        ) : isAvailabilityExceptionsLoading ? null : (
          <p className="m-auto pb-6">{selectedDate ? noAvailableSpotsPlaceholder : placeholder}</p>
        )}
      </div>
    </div>
  );
};

export { AvailabilityDateTimePicker };
